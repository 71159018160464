<template>
  <div class="vx-row">
    <div class="vx-col w-full md:w-2/3">
      <EmailApp />
    </div>
    <div class="vx-col w-full md:w-1/3">
      <!-- <vx-field class="w-full" type="input" placeholder="Search monitoring" value="" @input="search" /> -->
      <SubscriptionQuote class="mb-base" />
      <vx-card class="mb-base" title="active">
        <template slot="title">
          <h6 class="font-semibold">Detect changes made to web pages</h6>
        </template>
        <div class="text-sm">
          <p class="mb-5">Don't miss an update, market signal, important news, or even small adjustments.</p>
          <p
            >Create an automation for each page you want to monitor. As soon as a visual change is detected, you receive a notification and you can
            easily see what has changed.</p
          >
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import EmailApp from '@/views/emails/components/Email';
import SubscriptionQuote from '@/components/SubscriptionQuote';
export default {
  components: {
    SubscriptionQuote,
    EmailApp
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
  beforeCreate() {},
  created() {},
  mounted() {}
};
</script>
