<template>
  <div class="subscription-details" v-if="tenant">
    <vx-card title="active" v-for="({ title, used, max, upsell }, i) in (tenant.plan || {}).quotas || []" :key="i">
      <template slot="title" v-if="title">
        <h6 class="font-semibold">{{ title }}</h6>
      </template>
      <template slot="actions">
        <pre>{{ used || 0 }}<span class="font-bold">/{{ max || 0 }}</span></pre>
      </template>
      <vs-progress :percent="((used || 0) / (max || 1)) * 100" height="10" color="warning"></vs-progress>
      <p class="text-sm mt-5" v-if="upsell">
        {{ upsell }}
      </p>
    </vx-card>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  data() {
    return {};
  },
  computed: {
    ...mapState('accounts', ['tenant'])
  }
};
</script>
