<!-- =========================================================================================
    File Name: MailItem.vue
    Description: Mail Item - Displays mail item
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="mail__mail-item sm:px-4 px-2 py-4" :class="{ 'mail__opened-mail': !mail.unread }">
    <!-- MAIL ROW 1 : META -->
    <div class="flex w-full">
      <div class="flex w-full justify-between items-start">
        <div class="mail__details">
          <h5 class="flex mb-1 text-sm items-center" :class="{ 'font-semibold': mail.unread }">
            <strong class="mr-1">From:</strong>{{ mail.sender_name }}
            <vs-chip transparent color="dark" class="ml-1 p-0" style="min-height: 20px;">
              <span class="block pr-3 text-xs">{{ mail.sender }}</span>
            </vs-chip>
          </h5>
          <span class="text-sm">{{ mail.subject || 'Subject not available' }}</span>
        </div>

        <div class="mail-item__meta">
          <!-- <div class="email__labels hidden sm:flex items-center">
            <div class="h-2 w-2 rounded-full mr-2" :class="'bg-' + labelColor(label)" v-for="(label, index) in mail.labels" :key="index"></div>
          </div> -->
          <p class="mb-0 text-sm">
            Sent to <span class="font-semibold">John Doe </span>
            <span class="font-light text-xs">
              <vs-icon icon-pack="feather" icon="icon-at-sign" class="text-xs"></vs-icon>
              {{ mail.time | moment }}
            </span>
          </p>
        </div>
      </div>
    </div>
    <!-- /MAIL ROW 1 -->

    <!-- MAIL ROW 2 : MSG & ACTIONS -->
    <div class="flex w-full mt-2">
      <div class="flex items-center ml-1">
        <feather-icon
          icon="StarIcon"
          class="cursor-pointer"
          :svgClasses="[{ 'text-warning fill-current stroke-current': mail.isStarred }, 'w-5', 'h-5']"
          @click.stop="toggleIsStarred"
        ></feather-icon>
      </div>
      <div class="mail__message truncate ml-3">
        <span class="text-sm">{{ mail.message | filter_tags }}</span>
      </div>
    </div>
    <!-- /MAIL ROW 2 -->
  </div>
</template>

<script>
export default {
  props: {
    mail: {
      type: Object,
      required: true
    },
    isSelected: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      isSelectedMail: this.isSelected
    };
  },
  watch: {
    isSelected(value) {
      this.isSelectedMail = value;
    },
    isSelectedMail(val) {
      val ? this.$emit('addToSelected', this.mail.id) : this.$emit('removeSelected', this.mail.id);
    }
  },
  computed: {
    labelColor() {
      return label => {
        const tags = this.$store.state.email.mailTags;
        return tags.find(tag => {
          return tag.value === label;
        }).color;
      };
    }
  },
  methods: {
    toggleIsStarred() {
      const payload = { mailId: this.mail.id, value: !this.mail.isStarred };
      this.$store.dispatch('email/toggleIsMailStarred', payload);
    }
  }
};
</script>
